import { GetServerSideProps } from 'next'
import React from 'react'
import { getClientIp } from '@/lib/getClientIp'
import { getHTTPProtocol } from '@/lib/subdomain'
import { axiosGetRequest } from '..'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { SWRConfig } from 'swr'
import { defaultSWRConfig, getLocaleFromSSR, handleHelpCenterRedirect } from '@/lib/utils'
import DevDocsLayout from '@/components/DevDocsLayout'
import MainCenter from '@/components/docs/MainCenter'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { useHydrateAtoms } from 'jotai/utils'
import { meilisearchClientAtom } from '@/atoms/orgAtom'

const Index: React.FC<{ docsData: any; fallback: any }> = ({ docsData, fallback }) => {
  return (
    <SWRConfig value={{ fallback, ...defaultSWRConfig }}>
      <DevDocsLayout hideMinimap={true}>
        <MainCenter docsData={docsData} />
      </DevDocsLayout>
    </SWRConfig>
  )
}

export default Index

export const getServerSideProps: GetServerSideProps = async (context: any) => {
  const host = context.req.headers['x-forwarded-host'] || context.req.headers['host']
  const cookie = context.req.headers.cookie

  const locale = await getLocaleFromSSR(context)

  const ip = getClientIp(context)
  const http = getHTTPProtocol()
  const [org, user, helpCenter, helpCenterSearchKey] = await Promise.allSettled([
    axiosGetRequest(`${http}${host}/api/v1/organization`, cookie, ip),
    axiosGetRequest(`${http}${host}/api/v1/user`, cookie, ip),
    axiosGetRequest(
      `${http}${host}/api/v1/helpcenter?withStructure=true&locale=${locale}`,
      cookie,
      ip
    ),
    axiosGetRequest(`${http}${host}/api/v1/helpcenter/search/apiKey`, cookie, ip),
  ])
  const fallback = {}

  if (org.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/organization': org.value.data })
  } else {
    return {
      redirect: { destination: '/', permanent: false },
    }
  }

  if (user.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/user': user.value.data })
  }

  let helpCenterId = ''
  if (helpCenter.status === 'fulfilled') {
    helpCenterId = helpCenter.value?.data?.helpCenterId
    Object.assign(fallback, {
      ['/v1/helpcenter?withStructure=true&locale=' + locale]: helpCenter.value.data,
    })
  }

  if (helpCenterSearchKey.status === 'fulfilled') {
    Object.assign(fallback, {
      ['/v1/helpcenter/search/apiKey']: helpCenterSearchKey.value.data,
    })
  }

  if (org.status === 'fulfilled') {
    let translate = {}
    if (context.locale) {
      translate = await serverSideTranslations(context.locale)
    }

    if (helpCenter.status === 'fulfilled') {
      const helpCenterRedirect = handleHelpCenterRedirect(helpCenter, context, locale)

      if (helpCenterRedirect) {
        return helpCenterRedirect
      }
    }

    return {
      props: {
        fallback,
        docsData: helpCenter.status === 'fulfilled' ? helpCenter.value.data : {},
        domainType: 'feedback',
        helpCenterUrlParts: {
          locale: locale,
          subpath: '/help/',
          helpCenterId,
          articleId: '',
        },
        meilisearchApiKey:
          helpCenterSearchKey.status === 'fulfilled' ? helpCenterSearchKey.value.data.apiKey : null,
        allowAllCsp: true,
        ...translate,
      },
    }
  }

  return { redirect: { destination: '/' }, props: {} }
}
